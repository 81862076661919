import gsap                     from 'gsap';
import { ScrollToPlugin, Sine } from 'gsap/all';
import Utils                    from '../utils';
import $                        from 'jquery-slim/dist/jquery.slim';
import HorizontalScroll         from '../helpers/horizontalScroll';

const utils = new Utils();  // TODO: don't use class constructor...
gsap.registerPlugin(ScrollToPlugin, Sine);

export default class Navigation {
  constructor(viewport) {
    // Initialise
    this.initAnchorScroll();
    // Horizonal scroll
    if (window.innerWidth >= 1024)
      viewport.once('viewport:loaded', () => {
        // Add class
        $('body').addClass('has-shadow');
        // Horizontal scrolling kicks
        this.hs = new HorizontalScroll(viewport, {
          blocks: $('.scene'),
          container: $('.h-container'),
          parallax: [
            {
              el: $('#layer--front'),
              factor: 2,
            },
            {
              el: $('#layer--bottom'),
              factor: 0.25,
            },
            {
              el: $('#layer--front-1'),
              factor: 0.8,
            },
          ],
          isAnimated: true,
          skewReducer: 100,
          skewLimit: 100,
        });

        // Watch the current item in view.
        this.hs.on('inview', (what) => {
          this._updateNavIndication(what);
          this._updateNavIndication(what);
          // Randomize start texts!
          let txts = $('.in__txt');
          let rn = Math.round(Math.random() * txts.length ) - 1;
          $('.in__txt').hide();
          $('.in__txt').eq(rn).show();
        });

      });
  }


  initAnchorScroll() {
    //  bind scroll to anchor links (Vertical only)
    $(document).on('click', 'a[href^=\'#\']', e => {
      var id = $(e.target).attr('href');
      if ($(id).length > 0) {
        e.preventDefault();
        // trigger scroll
        if (this.hs)
          this.hs.to(id);
        else
          gsap.to(window, { duration: 1, scrollTo: id });

        // Update the menu item with active class
        $('#nav a').removeClass('active');
        $(e.target).addClass('active');

        // if supported by the browser we can even update the URL.
        if (window.history && window.history.pushState) {
          history.pushState('', document.title, id);
        }
      }
    });
  }

  _updateNavIndication(to) {
    // Update the menu item with active class
    $('#nav a').removeClass('active');
    $('#nav li').eq(to).children('a').addClass('active');
  }
}
