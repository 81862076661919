import 'bulma/css/bulma.css';
import '../style/style.scss';
import Router          from 'vanilla-router';
import Viewport        from './components/Viewport';
import Navigation      from './components/Navigation';
import Analytics       from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import $               from 'jquery-slim';

export class interactivesites {
  constructor() {
    console.log('%c INTERACTIVESITES NEW ZEALAND ', 'background: #222; color: #bada55');

    this.analytics = null;

    this.setupComponents();
    this.setupRoutes();
    this.initAnalytics();

  }

  setupComponents() {
    this.viewport = new Viewport();
    this.navigation = new Navigation(this.viewport);

    let txts = $('.in__txt');
    let rn = Math.round(Math.random() * txts.length) - 1;
    $('.in__txt').eq(rn).show();

    // SAtop scroll
    $('.scroll-content').on('mousewheel DOMMouseScroll touchmove', function(e) {
      e.stopPropagation();
    });

  }

  setupRoutes() {
    this.router = new Router({
      mode: 'history',
      page404: path => {
        console.log('"/' + path + '" Page not found');
      },
    });
  }

  initAnalytics() {
    // Check localtStorage
    let opted = localStorage.getItem('dylm_ga_opt');

    if (!opted) {
      $('#cookieopts').show();
      $('#ga_in').one('click', () => {
        localStorage.setItem('dylm_ga_opt', 1);
        this.triggerAnalytics();
        $('#cookieopts').hide();
      });
      $('#ga_out').one('click', () => {
        localStorage.setItem('dylm_ga_opt', 0);
        $('#cookieopts').hide();
      });
    } else if (+opted === 1) {
      this.triggerAnalytics();
    }


  }

  triggerAnalytics() {
    // Init
    this.analytics = Analytics({
      app: 'marcosiebert.de',
      plugins: [
        googleAnalytics({
          trackingId: 'UA-762127-2',
        }),
      ],
    });

    this.analytics.page();

    $('#nav a').on('click', (e) => {
      let id = $(e.target).attr('data-title');
      /* Track a custom event */
      this.analytics.track('clickedNav', {
        category: 'Navigation',
        label: id,
      });
    });

  }
}

(function($) {
  let app = new interactivesites();
})();
