import VirtualScroll from 'virtual-scroll';
import raf           from 'raf';
import transform     from 'prefix';
import $             from 'jquery-slim';
import EventEmitter  from 'eventemitter3';


/**
 * Horizontal Scroll
 * https://github.com/corentinfardeau/horizontal-scroll
 * TODO: Remove the mix of $ and vanillaJS, use latter only.
 *
 */
export default class HorizontalScroll extends EventEmitter {
  constructor(viewport, opts) {
    super();

    let self = this;
    this.viewport = viewport;
    this._bind();

    // Set default options
    this.options = $.extend(
      {
        container: opts.container,
        blocks: opts.blocks,
        isAnimated: opts.isAnimated || false,
        spring: opts.spring || 0.1,
        skewReducer: opts.skewReducer || 20,
        skewLimit: opts.skewLimit || 30,
        // Any parallax targets?
        parallax: [],
        // watch elements in view?
        watchInView: true,
      },
      opts,
    );

    this.vars = {
      scrollValue: 0,
      oldScrollValue: 0,
      scrollTarget: 0,
      scrollLeft: 0,
      scrollRight: 0,
      spring: this.options.spring,
      direction: 0,
      speed: 0,
      speedTarget: 0,
      // Watch position of current slide (int marks the full screen width)
      inViewOld: 0,
      inView: 0,
    };

    this.wrapper = document.createElement('div');
    this.wrapper.setAttribute('class', 'horizontal-scroll');

    this.vs = new VirtualScroll();
    this.transform = transform('transform');

    this.raf = raf;

    this._setUI();
    this._addEvents();

    window.setTimeout(() => {
      self.onResize();
    }, 500);

    this.viewport.on('viewport.resize', e => {
      self.onResize();
    });
  }

  // PRIVATE

  /**
   * Biding methods
   *
   */
  _bind() {
    this._update = this._update.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  /**
   * Add all events
   *
   */
  _addEvents() {
    let me = this;
    this.vs.on(this._onScroll, this);
    this.raf(this._update);
    window.addEventListener('resize', this.onResize);

    // Buttons, if any
    // Init next/prev buttons for horizontal scrolling
    $(document).on('click', '.swiper-button-next', e => {
      me.next(1);
    });
    $(document).on('click', '.swiper-button-prev', e => {
      me.next(-1);
    });
  }

  /**
   * Remove all events
   *
   */
  _removeEvents() {
    this.raf.cancel(this._update);
    this.raf(this._update);
    window.removeEventListener('resize', this.onResize);
    $(document).off('click', '.swiper-button-next');
    $(document).off('click', '.swiper-button-prev');
  }

  /**
   * Set the UI
   *
   */
  _setUI() {

    // Stop overflow
    $('html,body').css('overflow', 'hidden');

    $.extend(this.wrapper.style, {
      position: 'absolute',
      top: '0',
      left: '0',
      'backface-visibility': 'hidden',
      'will-change': 'transform',

      // OPT: Flex
      'align-items': 'center',
      'display':'flex'
    });

    $.extend(this.options.container[0].style, {
      'white-space': 'nowrap',
      position: 'relative',

      // OPT: Flex
      display: 'flex',
      'flex-direction': 'column'
    });

    Array.prototype.forEach.call(this.options.blocks, block => {
      block.style.display = 'inline-block';
      block.style.verticalAlign = 'bottom;';

      // OPT: Flex
      // block.style.alignItems = 'center';
      // block.style.justifyContent = 'center';
      block.style.display = 'flex';
      block.style.flex = '1';


      if (this.options.container[0]) {
        this.options.container[0].replaceChild(this.wrapper, block);
        this.wrapper.appendChild(block);
      }
    });
    if (this.options.container[0]) this.options.container[0].appendChild(this.wrapper);
  }

  /**
   * Trigger when user scroll
   *
   */
  _onScroll(e) {
    if (e.deltaY > 0) {
      this.vars.direction = 1;
    } else {
      this.vars.direction = -1;
    }
    this.vars.scrollTarget += e.deltaY * -1;
    this.vars.scrollTarget = Math.round(
      Math.max(this.vars.scrollLeft, Math.min(this.vars.scrollTarget, this.vars.scrollRight)),
    );
  }

  /**
   * RAF
   *
   */
  _update() {
    // Do not scroll on mobile, use native up/dn
    // console.log('scrolling...', this.viewport)
    if (this.viewport.desktop) {
      // SCROLL VALUE
      this.vars.scrollValue += (this.vars.scrollTarget - this.vars.scrollValue) * this.vars.spring;

      // DELTA
      let delta = this.vars.scrollTarget - this.vars.scrollValue;
      let skew = delta / this.options.skewReducer;
      this.vars.speed = this._clamp(-skew, -this.options.skewLimit, this.options.skewLimit);

      // TRANSFORM
      if (this.options.isAnimated) {
        this.wrapper.style[
          this.transform
          ] = `translate3d(-${this.vars.scrollValue}px, 0 ,0) skewX(${this.vars.speed}deg)`;
      } else {
        this.wrapper.style[this.transform] = `translate3d(-${this.vars.scrollValue}px, 0 ,0)`;
      }

      if (this.options.watchInView) {
        this.vars.inView = Math.round(this.vars.scrollValue / window.innerWidth);
        if (this.vars.inView !== this.vars.inViewOld) {
          // Emit the event for any listeners
          this.emit('inview', this.vars.inView);
        }
        this.vars.inViewOld = this.vars.inView;
      }

      this.vars.oldScrollValue = this.vars.scrollValue;
      // Show / hide the prev button
      if (this.vars.scrollValue > 10) {
        $('.swiper-button-prev').show();
      } else {
        $('.swiper-button-prev').hide();
      }
    }
    this.raf(this._update);
    // Parallax?
    this.doParallax();
  }

  /**
   * Move parallaxed items
   * TODO: make option to add multiple elements with factor n
   */
  doParallax() {
    this.options.parallax.map((p, i) => {
      if (this.options.isAnimated) {
        this.options.parallax[i].el.get(0).style[this.transform] = `translate3d(-${this.vars
          .scrollValue * this.options.parallax[i].factor}px, 0 ,0) skewX(${this.vars.speed}deg)`;
      } else {
        this.options.parallax[i].el.get(0).style[this.transform] = `translate3d(-${this.vars
          .scrollValue * this.options.parallax[i].factor}px, 0 ,0)`;
      }
    });
  }

  /**
   * CLAMP
   *
   */
  _clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
  }

  // PUBLIC

  /**
   * Progress on button click
   */
  next(direction) {
    this.vars.direction = direction || 1;
    this.vars.scrollTarget += (this.viewport.width / 4) * direction;
    this.vars.scrollTarget = Math.round(
      Math.max(this.vars.scrollLeft, Math.min(this.vars.scrollTarget, this.vars.scrollRight)),
    );
  }

  to(id) {
    let target = $(id);
    // Get the x position of the element
    let xTo = target.position().left;
    // Move to
    this.vars.scrollTarget += xTo - this.vars.scrollValue;
    this.vars.scrollTarget = Math.round(
      Math.max(this.vars.scrollLeft, Math.min(this.vars.scrollTarget, this.vars.scrollRight)),
    );
  }

  /**
   * Destroy variables and bind events
   *
   */

  resetToZero() {
    this.vars.scrollTarget = 0;
  }

  onResize() {
    this.vars.scrollLeft = 0;
    this.vars.scrollRight = this.wrapper.getBoundingClientRect().width - window.innerWidth;
  }

  destroy() {
    this._removeEvents();
    delete this;
  }
}
