import $                 from 'jquery-slim';
import { EventEmitter }  from 'eventemitter3';
import OverlayScrollbars from 'overlayscrollbars';

const SETTINGS = {
  BREAKPOINTS: {
    small: 650,
    tablet: 768,
    desktop: 930,
  },
};

export default class Viewport extends EventEmitter {
  constructor(options) {
    super(options);

    let me = this;

    // Runtime vars
    this.small = false;
    this.tablet = false;
    this.desktop = false;
    this.width = null;
    this.height = null;
    this.deviceOrientation = null;
    this.isTouchDevice = false;

    //
    window.onload = () => this._onWindowLoaded();
    // DOM ready
    $(document).ready(() => {
      this.emit('viewport:ready');
    });

    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        document.body.classList.add('is-touch-device');
        this.isTouchDevice = true;
        // we only need to know once that a human touched the screen, so we can stop listening now
        window.removeEventListener('touchstart', onFirstTouch, false);
      },
      false,
    );

    /**
     * Basic Check for Mobile
     */
    this.isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (
          me.isMobile.Android() ||
          me.isMobile.BlackBerry() ||
          me.isMobile.iOS() ||
          me.isMobile.Opera() ||
          me.isMobile.Windows()
        );
      },
    };

    /**
     * Throttle Resize-triggered Events [ ]
     * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
     *
     * @param  {Object} ) {				var     timers [description]
     * @return {[type]}   [description]
     */
    this._waitForFinalEvent = (() => {
      var timers = {};
      return function(callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = 'Don\'t call this twice without a uniqueId';
        }
        if (timers[uniqueId]) {
          clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, 500);
      };
    })();
  }

  // -------------------------------------------------------
  // Private
  _onWindowLoaded() {
    // Trigger initial measurement
    this._onWindowResize();
    // Window resize listeners (TODO: remove, use EE)
    this._updateViewportDimensions();
    $(window).on('resize', () => this._onWindowResize);

    $(window).on('orientationchange', () => this._onOrientationChange());

    // Nano scroller init
    OverlayScrollbars(document.querySelectorAll('nano'), { });


    // Notify
    this.emit('viewport:loaded');
  }

  /**
   * On resize, update viewport
   */
  _onWindowResize() {
    let me = this;
    // do not trigger the event every frame...
    me._waitForFinalEvent(() => {
      me._updateViewportDimensions();
      me.emit('viewport:resize');
    });
  }

  /**
   * Orientation Change Event
   * @private
   */
  _onOrientationChange() {
    // Hack
    if (window.innerWidth < 1025) {
      location.reload();
    }
    // Emit
    this.emit('orientationchange', screen.orientation);
  }

  /*
   * Get Viewport Dimensions
   * returns object with viewport dimensions to match css in width and height properties
   * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
   */
  _updateViewportDimensions() {
    let me = this;
    let w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight,
      small,
      tablet,
      desktop;

    if (x < SETTINGS.BREAKPOINTS.small) {
      small = true;
      tablet = false;
      desktop = false;
    } else if (x > SETTINGS.BREAKPOINTS.small && x <= SETTINGS.BREAKPOINTS.tablet) {
      small = false;
      tablet = true;
      desktop = false;
    } else if (x > SETTINGS.BREAKPOINTS.tablet) {
      small = false;
      tablet = false;
      desktop = true;
    }

    // return {
    me.width = x;
    me.height = y;
    me.small = small;
    me.tablet = tablet;
    me.desktop = desktop;
  }
}
